<template>
	<div id="productdetail">
		<div class="productdetail_in">
			<div class="top">
				<detailheader></detailheader>
				<div class="breadcrumb">
					<bread-crumb></bread-crumb>
				</div>
			</div>
			<div class="product_info">
				<div class="product_info_in">
					<div class="product_info_img">
						<!-- <img :src="imgUrlFront+productInfo.ListImageUrl" alt='找不到图片资源'/> -->
						<img :src="imgUrlFront+productInfo.ListImageUrl" alt='找不到图片资源'/>
					</div>
					<div class="product_info_detail">
						<h2 class="title">{{productInfo.ServiceItemName}}</h2>
						<!-- <div class="tag">{{productInfo.Description}}</div> -->
						<div class="price" v-if="period_value == ''">原价<span>￥</span><span>{{productInfo.StartPrice/100}}</span><span>起</span></div>
						<div class="price" v-else-if="period_value != '' && period_value.FrontMoney == period_value.NowPrice">现价<span>￥</span><span>{{period_value.NowPrice/100}}</span>
							<div class="linethrough">原价 ￥{{period_value.OriginPrice/100}}</div>
							<span class="paystyle">支付形式:{{period_value.PayTypeName}}</span>
						</div>
						<div class="price" v-else-if="period_value != '' && period_value.FrontMoney != period_value.NowPrice">
							现价<span>￥</span><span>{{period_value.NowPrice/100}}<span></span>(预支付:<strong style="font-size: 14px;">￥</strong>{{period_value.FrontMoney/100}})</span>
							<div class="linethrough">原价 ￥{{period_value.OriginPrice/100}}</div>
							<span class="paystyle">支付形式:{{period_value.PayTypeName}}</span>
						</div>
						<div class="service_region">
							<span>项目规格</span>
							<el-select v-model="spec_value" value-key="FLnkID" placeholder="请选择" @change="chooseSpec">
								<el-option
									v-for="item in spec"
									:key="item.FLnkID"
									:label="item.SpecName"
									:value="item">
								</el-option>
							
							</el-select>
						</div>
						<div class="service_cycle">
							<span>项目周期</span>
							<el-select v-model="period_value" value-key="FLnkID" placeholder="请选择" @change="choosePeriod" >
								<el-option
								v-for="item in period"
								:key="item.FLnkID"
								:label="item.PeriodName"
								:value="item"
								>
								</el-option>
							</el-select>		
						</div>
						<div class="service_cycles" v-if="hasOrders>0">
							<span class="num">数量</span>
							<el-input-number v-model="num"  :min="1" :max="9999" ></el-input-number>
						</div>
						
						<div class="product_button">
							<!-- <el-button class="buy_now" @click="toBuy(productInfo)">立即购买</el-button> -->
							<el-button class="buy_now" @click="miniProQrCodeShow = true">立即购买</el-button>
							<!-- <el-button class="consulting_service">咨询客服</el-button> -->
						</div>
					</div>
				</div>
			</div>	
			
			
			<div class="process">
				<div class="process_tab">
					<!-- <ul>
						<li>服务介绍</li>
						<li>办理流程</li>
						<li>所需材料</li>
						<li>您可获得</li>
						<li>服务保障</li>
						<li>服务评价</li>
					</ul> -->
				</div>
				<div class="intro_step intro_step1">
					<div class="top_img">
						<img :src="titleList[0]" alt="找不到图片">
					</div>
					<div class="step1">
						<div class="step1_left">
							<div >
								<i></i>
								<p>
									{{ productInfo.Description }}
								</p>
							</div>
							<div >
								<i></i>
								<p>
									<img class="successimg" src="~/assets/images/detail/productdetail/sccuess.png" alt="找不到图片">
									<span>顺丰配送</span>
									<img class="successimg" src="~/assets/images/detail/productdetail/sccuess.png" alt="找不到图片">
									<span>专业顾问</span>
									<img class="successimg" src="~/assets/images/detail/productdetail/sccuess.png" alt="找不到图片">
									<span>发票</span>
									<img class="successimg" src="~/assets/images/detail/productdetail/sccuess.png" alt="找不到图片">
									<span>信息安全</span>
								</p>
							</div>
						</div>
						<div class="step1_right">
							<img src="~/assets/images/detail/productdetail/product.png" alt="找不到资源">
						</div>
					</div>
				</div>
				<div class="intro_step intro_step2" v-for="(item,index) in productInfo.DetailImageUrl" :key="index">
					<div class="top_img">
						<img :src="titleList[index]" alt="找不到图片">
					</div>
					<div class="step_content">
						<img :src="imgUrlFront + productInfo.DetailImageUrl[index]" alt="找不到图片">
					</div>
				</div>
				<div class="intro_step intro_step6">
					<div class="top_img">
						<img :src="titleList[5]" alt="找不到图片">
					</div>
					<div class="comment" v-if="commentList.length>0">
						<div class="comment_single" v-for="(item,index) in commentList" :key="index">
							<div class="userinfo">
								<img v-if="item.ImageUrl.length != ''" :src="imgUrlFront + item.ImageUrl"  alt="找不到图片">
								<img v-else src="~/assets/images/detail/productdetail/userhead.png" alt="找不到图片" />
								<span>{{item.NickName}}</span>
							</div>
							<div class="comment_info">
								<div class="evaluation_score">
									<el-rate
										v-model="item.Score"
										disabled
										:colors="colors"
										 show-score
										 text-color="#999"
										>
									 </el-rate>
									 <span>
										 <!-- 评论时间 -->
									 </span>
								</div>
								
								<p class="comment_content">{{ item.Comment }}</p>
								
								<div class="buy_thing_info">
									<span>{{ item.SpecName }} {{ item.PeriodName }}</span>
									<span class="buy_time">购买时间：{{item.OrderTimeDisplay[0]}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="comment nocomment" v-if="commentList.length==0"><span>暂无评价</span></div>
				</div>
			</div>
			<footer-page></footer-page>
			<!-- 展示小程序二维码 -->
      	<el-dialog
        title="提示"
        :visible.sync="miniProQrCodeShow"
        width="300px"
        >
        <div class="qrcodeshow">
            <img src="@/assets/images/home/codes.jpg" alt="找不到图片">
        </div>
        <div style="margin-top: 15px;">
            <p>请扫描屏幕中二维码打开微信小程序办理</p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="miniProQrCodeShow = false">完 成</el-button>
        </span>
        </el-dialog>
		</div>
	</div>
</template>

<script>
import footerPage from "@/components/common/footer/footerPage.vue";
import BreadCrumb from "@/components/common/breadcrumb/BreadCrumb.vue";
import detailheader from "@/components/content/detail/detailheader.vue";
// import loginFrame from '@/components/content/login/loginFrame'
export default {
  name: "ProductDetail",
  data() {
    return {
      productInfo: "", //上个页面传入的产品信息
      spec: [], //规格
      spec_value: "", //选择规格的值
      hasOrders: 0,
      num: 1,
      period: [], //周期
      period_value: "", //选中的周期值
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"], //评分星星颜色从左至右显示
      commentList: [], //评论列表
      pageIndex: 1, //页码
      PageSize: 4, //页面容量

      titleList: [
        require("@/assets/images/detail/productdetail/service_step1.png"),
        require("@/assets/images/detail/productdetail/service_step2.png"),
        require("@/assets/images/detail/productdetail/service_step3.png"),
        require("@/assets/images/detail/productdetail/service_step4.png"),
        require("@/assets/images/detail/productdetail/service_step5.png"),
        require("@/assets/images/detail/productdetail/service_step6.png"),
			],
			miniProQrCodeShow: false,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
	},
	imgUrlFront(){
		return sessionStorage.getItem('apiImgUrl')
	}
  },
  components: {
    // loginFrame,
    BreadCrumb,
    footerPage,
    detailheader,
  },
  created() {
	let flnkid = this.$route.query.flnkid
	this.$axios("/API/Get_SingleServiceItem", {
      Version: this.Version,
      ServiceItemGuid: flnkid,
      PageIndex: this.PageIndex,
      PageSize: this.PageSize,
      JZToken: "",
      UserGuid: "",
    }).then((res) => {
	  //this.hasOrders = res.hasOrders;
	  res.data.DetailImageUrl = res.data.DetailImageUrl.split(',').slice(1,10)
	  this.productInfo = res.data
      res.comment.forEach((i) => {
        i.OrderTimeDisplay = i.OrderTimeDisplay.split(" ");
      });
      this.commentList = res.comment;
    });
    this.$axios("/API/Get_ServiceItem_Spec", {
      Version: this.Version,
      ServiceItemGuid: flnkid,
      JZToken: "",
      UserGuid: "",
    }).then((res) => {
      this.spec = res.data;
      this.spec_value = res.data[0];
      this.$axios("/API/Get_ServiceItem_Period", {
        Version: this.Version,
        ServiceItemGuid: flnkid,
        SpecGuid: this.spec_value.FLnkID,
        JZToken: "",
        UserGuid: "",
      }).then((res) => {
        this.period = res.data;
      });
    });
  
  },
  methods: {
    //选择规格周期
    chooseSpec(value) {
      let flnkid = this.productInfo.FLnkID;
      this.$axios("/API/Get_ServiceItem_Period", {
        Version: this.Version,
        ServiceItemGuid: flnkid,
        SpecGuid: value.FLnkID,
        JZToken: this.userinfo.TokenCode,
        UserGuid: this.userinfo.UserGuid,
      }).then((res) => {
        console.log(res.data);
        this.period = res.data;
        this.period_value = "";
      });
    },
    choosePeriod(value) {
      // this.period.forEach( i => {
      // 	if(value == i.FLnkID){
      // 		this.period_obj = i
      // 	}
      // })
    },

    //点击购买
    toBuy(item) {
      this.$router.push({
        name: "productbuy",
        query: {
          FLnkID: item.FLnkID,
          ServiceTypeGuid: item.ServiceTypeGuid,
        },
      });
    },
  },
};
</script>

<style lang="scss">
	@media(min-width: 1201px){
		@import "@/pages/detail/ProductDetail.scss";
	}
	@media(max-width: 1200px){
		@import "@/pages/detail/ProductDetail_phone.scss";
	}
	.qrcodeshow{
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		img{
				width: 160px;
				height: 160px;
		}
	}
</style>
